import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  ModalBody,
  Button,
  Label
} from 'reactstrap';
import Select from 'react-select';

import { axios } from 'ApiClient';
import { useSelector } from 'react-redux';
import Hook from '@/shared/hooks';
import RadioButton from '@/shared/components/RadioButton';
import CheckBoxField from '@/shared/components/CheckBox';
import Alert from '@/shared/easydev2/shared/components/Alert';

import WVFormatter from '@/shared/helpers/WVFormatter';

export const modalId = 'sendEmailModal';


const SendEmailModal = () => {
  const [loading, setLoading] = useState(false);
  const [includeAll, setIncludeAll] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailId, setEmailId] = useState(null);
  const [create, setCreate] = useState(true);
  const { showSuccess, showError } = Hook.useNoticeHook();
  const { data: dataModal, showModal: isShow, close } = Hook.useModalHook(modalId);
  const reload = Hook.useReloadTableHook();
  const total = useSelector((state) => state.tableRedux.total);
  const tableFilterUrl = useSelector((state) => state.tableRedux.tableFilterUrl);
  // const selectedRows = useSelector((state) => state.tableRedux.selectedRecordsIdsMultiselect);
  useEffect(async () => {
    if (isShow) {
      const res = await axios.get('/api/portal/opportunity/get-emails-from-hubspot');
      if (res.data.emails) {
        setEmails(res.data.emails.map(e => ({ label: e.name, value: e.id })));
      }
    }
  }, [isShow]);
  if (!dataModal || !dataModal.selectedRows) return false;
  const closeFunc = () => {
    setIncludeAll(false);
    setEmailId(null);
    setCreate(false);
    close();
  };
  const sendEmails = async () => {
    setLoading(true);
    console.log(tableFilterUrl);
    const filterParams = new URLSearchParams(tableFilterUrl);
    const filter = {};
    filterParams.forEach((value, key) => {
      
      if (filter[key]) {
        filter[key] = Array.isArray(filter[key]) ? [...filter[key], value] : [filter[key], value];
      } else {
        filter[key] = value;
      }
    });
    console.log(filter);
    if (!emailId) {
      return false;
    }
    try {
      const res = await axios.post('/api/portal/opportunity/post-send-opportunity-emails', {
        includeAll,
        ids: dataModal.selectedRows,
        filter: filter || {},
        emailId,
        shouldCreateInfluencer: create,
      });
      if (res.data.success) {
        showSuccess('Emails Scheduled Successfully');
        setTimeout(() => reload(), 3000);
        closeFunc();
      } else {
        showError(res.data.error);
      }
    } catch (e) {
      console.error(e);
      showError('Error in scheduling emails');
    }
    setLoading(false);
  };
  

  
  const { record } = dataModal;
  console.log(record, 'modalInfo');
  return (
    <Modal
      size="md"
      isOpen={isShow}
      toggle={closeFunc}
      className='ltr-support theme-light'
    >
      <ModalHeader toggle={closeFunc} tag="h4">
        Send Opportunity Emails
      </ModalHeader>
      <ModalBody>
        <>
          <div>{`You have selected ${dataModal.selectedRows.length} contact${dataModal.selectedRows.length !== 1 ? 's' : ''} to send email.`}</div>
          <div className="theme-light radio-in-prospects">
            <Label  >
              <span className='pr-3'>{`Do you wish to select all ${WVFormatter.formatIntNumber(total)} contacts?`}</span>
            <RadioButton
              key="includeAll"
              name="action"
              label="&nbsp;Yes"
              value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
              radioValue="includeAll"
              onChange={(e) => {
                setIncludeAll(e === 'includeAll');
              }}
            />
            <RadioButton
              key="notIncludeAll"
              name="action"
              label="&nbsp;No"
              value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
              radioValue="notIncludeAll"
              onChange={(e) => {
                setIncludeAll(e === 'includeAll');
              }}
            />
            </Label>
          </div>
        </>
        <div className="mt-3">
          <Label className='font-weight-bold'>Select HubSpot Email Template to Send</Label>
          <Select
            options={emails}
            value={emails.find(({ value }) => value === emailId)}
            onChange={(e) => setEmailId(e.value)}
            placeholder="Select HubSpot Email Template to Send"
          />
        </div>
        <div className="mt-3">
          <Label className='font-weight-bold'>New Creators</Label>
          <CheckBoxField
            name="displayInUiNo"
            label="Yes, create Creator Portal accounts for new Creators"
            value={create}
            onChange={event => setCreate(event.target?.checked)}
          />
        </div>
        <div>
          <Alert color="info" icon bordered closable={false}>
            <p>If a Creator Portal account is not yet on the platform, an account will be created automatically if this option is enabled. If the option is disabled, no account will be created.</p>
          </Alert>
        </div>
        
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="primary" onClick={() => sendEmails()} disabled={loading || !emailId}>Send Emails</Button>&nbsp;&nbsp;
        <Button color="secondary" onClick={closeFunc}>Cancel</Button>

      </ButtonToolbar>
    </Modal>
  );
};
export default SendEmailModal
