import React, { useState} from 'react';
// import RefreshIcon from 'mdi-react/RefreshIcon';
import { 
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
 } from 'reactstrap';
 import { useSelector, useDispatch } from 'react-redux';
 import ReportModal from '../Modal/ReportModal';
 import csvHelper from '@/shared/helpers/CSVHelper';
 import { fillAdvertisers } from '@/redux/reducers/admin/AdvertiserReducer';
 import { syncBrands } from '@/redux/reducers/admin/HubilReducer';
 import Hook from '../../../../../../../shared/hooks';


const DashboardActions = () => {
  const [modal, showModal] = useState(false);
  const refreshTable = Hook.useReloadTableHook();
  const dispatch = useDispatch();
  const advertisersLog = useSelector(state => state.advertiserReducer.logs.advertisers);
  const offersLog = useSelector(state => state.advertiserReducer.logs.offers);
  const loading = useSelector(state => state.advertiserReducer.loading.fillAdvertisers);
  // const loadingBrands = useSelector(state => state.hubilReducer.loadingBrands.syncBrands);
  const syncBrandsToHO = () => {
    dispatch(syncBrands(() => {

    }));
  }
  const refreshBrands = () => {
    dispatch(fillAdvertisers(() => {
      refreshTable()
    }));
    showModal(true);
    // this.setState({ isModalShow: true });
  }
  const downloadCSV = (type, name) => {
    const props = { advertisersLog, offersLog }
    const data = props[type] && props[type][name] ? props[type][name] : null;
    if (name === 'errors') {
      return csvHelper.downloadArrayCSV(data, 'message', `${type}-${name}.csv`);
    }
    return data ? csvHelper.downloadObjectCSV(data, `${type}-${name}.csv`) : false;
  }
  const closeModal = () => {
    if (!loading) {
      showModal(false);
    }
  }

  return (
    <>
      <UncontrolledButtonDropdown className="w-100">
        <DropdownToggle
          id="actionsMenu"
          caret
          color="primary"
          style={{
            padding: '4px 25px',
            height: '42px',
          }}
        >
          Actions
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => refreshBrands()}> Sync Brands HO {'->'} IL </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => syncBrandsToHO()}> Sync Brands HO {'->'} HS </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <ReportModal
          modal={modal}
          closeDialog={closeModal}
          advertisersLog={advertisersLog}
          offersLog={offersLog}
          loading={loading}
          downloadCSV={downloadCSV}
        />
    </>
  );
};
DashboardActions.propTypes = {

};


export default DashboardActions;
