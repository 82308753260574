import React from 'react';
import moment from 'moment';
import ApiClient from 'ApiClient';
import DatePicker from 'react-datepicker';
import TableCellContextMenu from '../../../Research/Prospects/components/custom/TableCellContextMenu';
import { updateRowParams } from '../../../../../../redux/reducers/admin/InfluencerProfileReducer';
import { addNotice } from '../../../../../App/store/store';
import { goToPage } from '../../../../../../shared/helpers/WVUtilities';
import { refresh } from '../../../../../../shared/helpers/models/ServiceAgreement';
import Assignments from '../../../ServiceAgreement/components/Assignments';

export default ({ dispatch, setAgreement, openAssignmentsModal, openResendModal }) => {
  const updateRow = (id, params) => {
    dispatch(updateRowParams({
      key: 'serviceAgreements',
      id,
      params,
    }));
  };

  const handleChangeDateSigned = async (creatorDateSigned, record) => {
    try {
      const oldValue = record.creatorDateSigned;
      updateRow(record._id, { creatorDateSigned });
      const data = new FormData();
      data.append('creatorDateSigned', creatorDateSigned || '');
      const api = new ApiClient();
      const res = await api.recordAction({
        resourceId: 'ServiceAgreement',
        recordId: record._id,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.data.record.errors) {
        const errKeys = Object.keys(res.data.record.errors);
        if (errKeys.length > 0) {
          const errKey = errKeys[0];
          dispatch(addNotice({
            message: res.data.record.errors[errKey].message,
            type: 'error',
          }));
          updateRow(record._id, { creatorDateSigned: oldValue });
        }
      }
    } catch (err) {
      dispatch(addNotice({
        message: err.message,
        type: 'error',
      }));
    }
  };

  return {
    offer: {
      title: 'Offer',
      valueFunc: record => ((record.type === 'keen') ? 'Service Provider Agreement' : record.offers[0].name),
      sort: record => ((record.type === 'keen') ? 'Service Provider Agreement' : record.offers[0].name),
    },
    deal_name: {
      title: 'Deal Name',
      valueFunc: record => (record.deals.length ? record.deals[0]?.dealname : ''),
      sort: record => (record.deals.length ? record.deals[0]?.dealname : ''),
      filter: record => (record.deals.length ? record.deals[0]?.dealname : ''),
    },
    deal_type: {
      title: 'Deal Type',
      valueFunc: record => (record.deals.length ? record.deals[0]?.dealTerms?.type_of_deal : ''),
      sort: record => (record.deals.length ? record.deals[0]?.dealTerms?.type_of_deal : ''),
      filter: record => (record.deals.length ? record.deals[0]?.dealTerms?.type_of_deal : ''),
    },
    status: {
      title: 'Status',
      valueFunc: (record) => {
        if (record.status === 'completed') {
          return (
            <div className="marker" style={{ color: '#fff', backgroundColor: 'green' }}>Complete</div>
          );
        }
        if (record.status === 'deleted') {
          return (
            <div className="marker" style={{ color: '#fff', backgroundColor: '#ed9f8a' }}>Deleted</div>
          );
        }
        return (
          <div className="marker" style={{ color: '#fff', backgroundColor: 'orange' }}>Pending</div>
        );
      },
      sort: (record) => {
        if (record.status === 'completed') return 1;
        if (record.status === 'deleted') return 2;
        return 0;
      },
      filter: (record) => {
        if (record.status === 'completed') return 'Complete';
        if (record.status === 'deleted') return 'Deleted';
        return 'Pending';
      },
    },
    date_sent: {
      title: 'Date Sent',
      valueFunc: record => (moment(record.createdAt).format('M/D/YYYY')),
      sort: record => (moment(record.createdAt).format('YYYY-MM-DD')),
    },
    date_signed: {
      title: 'Date Signed',
      valueFunc: (record) => {
        const { teams } = window.REDUX_STATE.session;
        if (
          (record.status !== 'deleted')
          && (record.creatorDateSigned || (record.status === 'completed'))
          && (teams.includes('super_powers') || teams.includes('management') || teams.includes('finance') || teams.includes('compliance'))
        ) {
          return (
            <DatePicker
              portalId="root"
              onChange={(newValue) => { handleChangeDateSigned(newValue, record); }}
              selected={record.creatorDateSigned ? moment(record.creatorDateSigned).toDate() : null}
              dateFormat="M/d/yyyy"
              className="form-control"
            />
          );
        }
        return record.creatorDateSigned ? moment(record.creatorDateSigned).format('M/D/YYYY') : '';
      },
      sort: record => (record.creatorDateSigned ? moment(record.creatorDateSigned).format('YYYY-MM-DD') : ''),
    },
    actions: {
      title: ' ',
      disableSortBy: true,
      width: 50,
      valueFunc: (record) => {
        const hsDealId = (record.deals.length && record.deals[0].hs_object_id) ? record.deals[0].hs_object_id : null;
        const contextMenuOptions = [
          { label: 'Edit', handler: () => goToPage(`/service-agreements/edit/${record._id}`, '_self'), disabled: ['completed', 'deleted'].includes(record.status) },
          { label: 'Assignments', handler: () => { openAssignmentsModal({
            title: 'Assignments',
            size: 'md',
            message: <Assignments agreement={record} />,
          }) }, disabled: (record.engine !== 'dropbox') },
          { label: 'Open in HubSpot', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${hsDealId}`, '_blank'), disabled: !hsDealId },
          { label: 'Preview', handler: () => goToPage(`/service-agreement/preview-document?id=${record._id}`, '_blank'), disabled: !record.filePath },
          { label: 'Download', handler: () => goToPage(`/service-agreement/download-document?id=${record._id}`, '_blank'), disabled: !record.filePath },
          { label: 'Refresh', handler: () => { refresh(record, { updateRow }) }, disabled: ((record.status === 'deleted')) },
          { label: 'Resend Notification (Dropbox)', handler: () => { setAgreement(record); openResendModal(true) }, disabled: (
            (record.status === 'deleted')
            || record.embeddedSigning
            || (record.engine !== 'dropbox')
            || !record.envelopeId
            || record.creatorDateSigned
            || (record.status !== 'pending')
          ) },
         ];

        if (record.status !== 'deleted') {
          contextMenuOptions.push({ label: 'Delete', handler: () => goToPage(`/service-agreement/delete-document?id=${record._id}`, '_self') });

        } else {
          contextMenuOptions.push({ label: 'Undo Deleted', handler: () => goToPage(`/service-agreement/undo-deleted-document?id=${record._id}`, '_self') });
        }

        return (
          <TableCellContextMenu options={contextMenuOptions} />
        );
      },
    },
  };
};
