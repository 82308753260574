import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  ModalBody,
  Button,
  Label
} from 'reactstrap';
import Select from 'react-select';

import { axios } from 'ApiClient';
import Hook from '@/shared/hooks';
import CheckBoxField from '@/shared/components/CheckBox';
import Alert from '@/shared/easydev2/shared/components/Alert';


export const modalId = 'reSendEmailModal';


const ReSendEmailModal = () => {
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailId, setEmailId] = useState(null);
  const [create, setCreate] = useState(true);
  const { showSuccess, showError } = Hook.useNoticeHook();
  const { data: dataModal, showModal: isShow, close } = Hook.useModalHook(modalId);
  const reload = Hook.useReloadTableHook();
  // const selectedRows = useSelector((state) => state.tableRedux.selectedRecordsIdsMultiselect);
  useEffect(async () => {
    if (isShow) {
      const res = await axios.get('/api/portal/opportunity/get-emails-from-hubspot');
      if (res.data.emails) {
        setEmails(res.data.emails.map(e => ({ label: e.name, value: e.id })));
      }
      const resData = await axios.get('/api/portal/opportunity/get-sent-email-data?id=' + dataModal.record._id);
      if (resData.data.success) {
        setEmailId(resData.data.emailId)
      }
    }
    if (dataModal.record?.dealObject?.ilDealData.influencer) {
      setCreate(false);
    } else {
      setCreate(true);
    }
  }, [isShow]);
  if (!dataModal || !dataModal.record) return false;
  const closeFunc = () => {
    setEmailId(null);
    setCreate(false);
    close();
  };
  const sendEmails = async () => {
    setLoading(true);
    if (!emailId) {
      return false;
    }
    try {
      const { data } = await axios.post('/api/portal/opportunity/post-resend-email', {
        id: record._id,
        emailId: emailId,
        shouldCreateInfluencer: create
      });
      if (!data.success) {
        throw new Error(data.error || 'Failed to refresh record');
      } else {
        // updateRecordFunction(unflatten(data.opportunityPopulated));
        showSuccess('Email Resent successfully!');
        reload();
        closeFunc();
      }
    } catch (error) {
      showError(error.error || 'Failed to Resend Email.');
    }
    setLoading(false);
  };



  const { record } = dataModal;
  console.log(record, 'modalInfo');
  return (
    <Modal
      size="md"
      isOpen={isShow}
      toggle={closeFunc}
      className='ltr-support theme-light'
    >
      <ModalHeader toggle={closeFunc} tag="h4">
        Resend Opportunity Emails
      </ModalHeader>
      <ModalBody>
        <div className="mt-3">
          <Label className='font-weight-bold'>Select HubSpot Email Template to Send (Automatically filled based on your last choice)</Label>
          <Select
            options={emails}
            value={emails.find(({ value }) => value === emailId)}
            onChange={(e) => setEmailId(e.value)}
            placeholder="Select HubSpot Email Template to Send"
          />
        </div>
        <div className="mt-3">
          <Label className='font-weight-bold'>New Creators</Label>
          <CheckBoxField
            name="displayInUiNo"
            label="Yes, create Creator Portal accounts for new Creators"
            value={create}
            onChange={event => setCreate(event.target?.checked)}
            disabled={dataModal.record?.dealObject?.ilDealData.influencer}
          />
        </div>
        {dataModal.record?.dealObject?.ilDealData.influencer ? (
          <div>
            <Alert color="warning" icon bordered closable={false}>
              <p>The Creator Portal account is already set up.</p>
            </Alert>
          </div>
        ) : (
          <div>
            <Alert color="info" icon bordered closable={false}>
              <p>If a Creator Portal account is not yet on the platform, an account will be created automatically if this option is enabled. If the option is disabled, no account will be created.</p>
            </Alert>
          </div>
        )}


      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="primary" onClick={() => sendEmails()} disabled={loading || !emailId}>Send Emails</Button>&nbsp;&nbsp;
        <Button color="secondary" onClick={closeFunc}>Cancel</Button>

      </ButtonToolbar>
    </Modal>
  );
};
export default ReSendEmailModal
