import React from 'react';
import { connect } from 'react-redux';
import {
  FormGroup, Label, Modal, Spinner, Button,
  Input, InputGroup, InputGroupAddon,
  Popover, PopoverHeader, PopoverBody,
} from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import ApiClient, { axios } from 'ApiClient';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import PropTypes from 'prop-types';
import recordToFormData from '../../../../../../shared/components/components/actions/record-to-form-data';
import WVValidator from '../../../../../../shared/helpers/WVValidator';
import fetchResourceData from '../../../../../../shared/helpers/WVRequest';
import withNotice from '../../../../../App/store/with-notice';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';


class AgentForm extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiClient();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailBlur = this.handleEmailBlur.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChangeHubilAgent = this.handleChangeHubilAgent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleElementChange = this.handleElementChange.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.showPasswordConfirm = this.showPasswordConfirm.bind(this);
    this.loadHubilAgentsOptions = this.loadHubilAgentsOptions.bind(this);
    this.state = {
      selectedHubilAgent: {
        id: null,
        title: '-- Select Existing Agent Contact --',
      },
      selectedHubilAgentOption: {
        value: null,
        label: '-- Select Existing Agent Contact --',
      },
      loading: {
        form: false,
      },
      agent: {
        params: {},
        errors: {},
        // populated: {},
      },
      showPassword: false,
      showPasswordConfirm: false,
      isPasswordPopoverOpen: false,
      hubilContactRecord: null,
      hsContactData: {
        existContact: false,
        contact: null,
        type: 'Agent',
      },
      hsContactInBlacklist: false,
      foundDupeViaEmail: false,
      loadingAgencies: false,
      agencyOptions: [],
    };
  }

  componentDidMount() {
    this.fetchAgencies();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modal === false && prevProps.modal !== this.props.modal) {
      this.fetchData();
    }
  }

  async loadHubilAgentsOptions(inputValue) {
    const { row } = this.props;
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'HubilAgent',
      query: inputValue,
    });
    const res = [];
    records.forEach((record) => {
      if (record.params.email !== null) {
        if (row && row.params && row.params.hsContactVid && (record.params.hs_object_id === row.params.hsContactVid)) {
          this.setState({
            selectedHubilAgent: record,
            selectedHubilAgentOption: {
              value: record.id,
              label: record.params.email,
            },
          });
        }
        res.push({
          value: record.id,
          label: record.params.email,
          params: record.params,
          record,
        });
      }
    });
    if (inputValue.includes('@') && inputValue.includes('.')) {
      const contactRecords = await api.searchRecords({
        resourceId: 'HubilContact',
        query: inputValue,
      });
      if (contactRecords && contactRecords.length === 1 && contactRecords[0].params.email === inputValue) {
        this.setState({
          hubilContactRecord: contactRecords[0],
        });
      } else {
        this.setState({
          hubilContactRecord: null,
        });
      }
    } else {
      this.setState({
        hubilContactRecord: null,
      });
    }
    return [{ value: null, label: '-- Create New Agent HubSpot Contact --', record: { params: { hs_object_id: 'new' }, id: null, title: '-- Create New Agent HubSpot Contact --' } }].concat(res);
  }

  static requiredData = [
    'contact',
    'agency',
    'company',
    'email',
    'first_name',
    'last_name',
  ];

  fetchData() {
    const { row } = this.props;
    if (row) {
      row.password = '';
      this.setState({
        agent: {
          id: row.id,
          params: {
            ...row.params,
            company: row.params.companyName,
            contact: row.params.hsContactVid,
            password: '',
          },
          errors: row.errors || {},
        },
      });
    } else {
      this.setState({
        agent: {
          params: {
            email: undefined,
            first_name: undefined,
            last_name: undefined,
            company: undefined,
            contact: undefined,
            adminUser: undefined,
          },
          errors: {},
        },
      });
    }
    const { hubilAgent } = this.props;
    if (hubilAgent) {
      this.handleChangeHubilAgent({ record: hubilAgent });
    }
    return null;
  }

  fetchAgencies = () => {
    fetchResourceData('Agency', {}, 'perPage=5000&sortBy=agencyName&direction=asc').then((res) => {
      this.setState({
        loadingAgencies: false,
        agencyOptions: res.records.filter(r => r.params?.status === 'verified').map(r => ({ value: r.id.toString(), label: r.params.agencyName })),
      });
    });
  }

  handleChange(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        agent: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        agent: {
          ...state.agent,
          params: {
            ...state.agent.params,
            [propertyOrRecord]: value,
          },
          errors: {
            ...state.agent.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }));
    }
  }
  handleChangeHubilAgent(selected) {
    if (selected) {
      const { adminUser } = this.props;
      this.setState({
        selectedHubilAgent: selected.record,
        selectedHubilAgentOption: {
          value: selected.record.id,
          label: selected.record.title,
        },
      });
      if (selected.record.title !== '-- Create New Agent HubSpot Contact --' || selected.record.params?.firstname === '-- Create New Agent HubSpot Contact --') {
        this.setState({
          hubilContactRecord: null,
          hsContactData: {
            existContact: false,
            contact: null,
            type: 'Agent',
          },
        });
      }
      this.handleChange('contact', selected.record.params.hs_object_id !== null && typeof selected.record.params.hs_object_id !== 'undefined' ? selected.record.params.hs_object_id : '');
      this.handleChange('email', selected.record.params.email !== null && typeof selected.record.params.email !== 'undefined' ? selected.record.params.email : '');
      this.handleChange('first_name', selected.record.params.firstname !== null && typeof selected.record.params.firstname !== 'undefined' ? selected.record.params.firstname : '');
      this.handleChange('last_name', selected.record.params.lastname !== null && typeof selected.record.params.lastname !== 'undefined' ? selected.record.params.lastname : '');
      this.handleChange('company', selected.record.params.company !== null && typeof selected.record.params.company !== 'undefined' ? selected.record.params.company : '');
      if (selected.record.params.hs_object_id === 'new') {
        this.handleChange('adminUser', adminUser && adminUser._id ? adminUser._id : null);
      }
      if (selected.record?.params?.blacklist_) {
        this.setState({
          hsContactInBlacklist: true,
        });
      } else {
        this.setState({
          hsContactInBlacklist: false,
        });
      }
    }
  }
  handleElementChange(event) {
    this.handleChange(`${event.target.name}`, event.target.value);
  }
  handleInputChange(name, event) {
    if (event.target.type === 'checkbox') {
      this.handleChange(name, event.target.checked);
    } else {
      this.handleChange(name, event.target.value);
    }
  }
  handleEmailBlur = (event) => {
    const { selectedHubilAgentOption } = this.state;
    if (event.target.value && event.target.value.includes('@') && event.target.value.includes('.')) {
      if (this.props.modalTitle === 'Edit Agent') {
        // check for agent with the same email
        axios.get(`/api/portal/agent/get-check-agent-exists-by-email?email=${encodeURIComponent(event.target.value.toLowerCase().trim())}&id=${this.state.agent.params._id}`).then((responce) => {
          if (responce.data.success) {
            this.setState({
              foundDupeViaEmail: responce.data.foundAgent,
            });
          }
        });
      } else {
        const email = event.target.value.toLowerCase().trim();
        if (selectedHubilAgentOption?.label?.toLowerCase()?.trim() !== email) {
          let contactFound = false;
          axios.get(`/api/portal/hubil-data/get-contact-exist?email=${encodeURIComponent(event.target.value.toLowerCase().trim())}`).then((response) => {
            if (response.data.success) {
              this.setState({
                hsContactData: {
                  existContact: response.data.existContact,
                  contact: response.data.contact,
                  type: response.data.type,
                },
              });
              contactFound = response.data.existContact;
            }
          });
          if (!contactFound) {
            axios.get(`/api/portal/blacklist/get-item-by-email?email=${encodeURIComponent(event.target.value.toLowerCase().trim())}`).then((response) => {
              if (response.data.success) {
                console.log(response.data.result);
                if (response.data.result) {
                  this.setState({
                    hsContactInBlacklist: true,
                  });
                } else {
                  this.setState({
                    hsContactInBlacklist: false,
                  });
                }
              }
            });
          }
        } else {
          this.setState({
            hsContactData: {
              existContact: false,
              contact: null,
              type: 'Agent',
            },
          });
          this.setState({
            hsContactInBlacklist: false,
          });
        }
      }
    }
  }

  showPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  showPasswordConfirm = () => {
    this.setState({
      showPasswordConfirm: !this.state.showPasswordConfirm,
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    // debugger;
    const { setSelectedAgentOption } = this.props;
    const { agent } = this.state;
    let isValid = true;
    this.constructor.requiredData.forEach((item) => {
      if (!agent.params[item] &&  (!agent.id || (['password', 'passwordConfirm'].indexOf(item) === -1))) {
        isValid = false;
        this.setState(state => ({
          agent: {
            ...state.agent,
            errors: {
              ...state.agent.errors,
              [item]: 'This field is required',
            },
          },
        }));
      }
    });
    if (agent.params.password && !WVValidator.isValidPassword(agent.params.password)) {
      isValid = false;
      this.setState(state => ({
        agent: {
          ...state.agent,
          errors: {
            ...state.agent.errors,
            password: 'Please follow the password requirements',
          },
        },
      }));
    }
    if (agent.params.passwordConfirm && agent.params.password !== agent.params.passwordConfirm) {
      isValid = false;
      this.setState(state => ({
        agent: {
          ...state.agent,
          errors: {
            ...state.agent.errors,
            passwordConfirm: 'Passwords don\'t match',
          },
        },
      }));
    }

    if (!isValid) {
      this.props.addNotice({
        message: 'Please Check form. Seems you did not fill some fields',
        type: 'warning',
      });
      return true;
    }
    const formData = recordToFormData(agent);
    if (!agent.id) {
      this.setState({
        loading: {
          form: true,
        },
        hsContactData: {
          existContact: false,
          contact: null,
          type: 'Agent',
        },
      });
    }
    axios({
      method: 'post',
      url: '/api/portal/agent/create-agent',
      data: Object.fromEntries(formData),
    }).then((response) => {
      this.setState({
        loading: {
          form: false,
        },
      });
      if (response.data.success === true) {
        const {
          _id,
          firstname,
          lastname,
          email,
        } = response.data.agent;
        setSelectedAgentOption({
          value: _id,
          label: email !== null ? email : `${firstname} ${lastname}`,
          record: { id: _id, params: response.data.agent },
        });
        this.setState({
          selectedHubilAgent: {
            id: null,
            title: '-- Select Existing Agent Contact --',
          },
          selectedHubilAgentOption: {
            value: null,
            label: '-- Select Existing Agent Contact --',
          },
        });
        this.closeFormDialog(response.data.agent.hsContactVid);
        this.props.addNotice({
          message: agent.id ? 'Agent has been successfully updated' : 'New Agent has been successfully added',
          type: 'success',
        });
      } else {
        this.props.addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
    }).catch((error) => {
      console.error(error);
      this.setState({
        loading: {
          form: false,
        },
      });
      this.props.addNotice({
        message: 'There was an error creating record, Check out console to see more information.',
        type: 'error',
      });
    });
    return false;
  }
  closeFormDialog(reloadAgent = null) {
    this.setState({
      selectedHubilAgent: {
        id: null,
        title: '-- Select Existing Agent Contact --',
      },
      selectedHubilAgentOption: {
        value: null,
        label: '-- Select Existing Agent Contact --',
      },
      hubilContactRecord: null,
      hsContactData: {
        existContact: false,
        contact: null,
        type: 'Agent',
      },
      hsContactInBlacklist: false,
      foundDupeViaEmail: false,
    });
    const { closeDialog } = this.props;
    closeDialog(reloadAgent);
  }
  togglePasswordPopover = () => {
    this.setState({
      isPasswordPopoverOpen: !this.state.isPasswordPopoverOpen,
    });
  }

  render() {
    const { row, modalTitle, modal } = this.props;
    const {
      agent,
      selectedHubilAgent,
      selectedHubilAgentOption,
      showPassword,
      showPasswordConfirm,
      hubilContactRecord,
      hsContactData,
      hsContactInBlacklist,
      foundDupeViaEmail,
      loadingAgencies,
      agencyOptions,
    } = this.state;

    return (
      <Modal
        isOpen={modal}
        toggle={this.closeFormDialog}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">{modalTitle}</h4>
        </div>
        <div className="modal__body finance-form">
          {this.state.loading.form &&
          (
            <div className="text-center"><Spinner color="primary" size="lg" /></div>
          )
          }
          {!this.state.loading.form &&
          (
            <form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label>Agent HubSpot Contact</Label>
                {selectedHubilAgent && typeof selectedHubilAgent.params !== 'undefined' && selectedHubilAgent.params.hs_object_id !== 'new' && (
                  <>
                    {/* eslint-disable-next-line react/jsx-no-target-blank,max-len */}
                    &nbsp;&nbsp;<a title="View Contact on HubSpot" target="_blank" href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${selectedHubilAgent.params.hs_object_id}/`}><i className="fas fa-external-link-alt" /></a>
                  </>
                )}
                <SelectAsync
                  // cacheOptions
                  value={selectedHubilAgentOption}
                  defaultOptions
                  loadOptions={this.loadHubilAgentsOptions}
                  onChange={this.handleChangeHubilAgent}
                  placeholder=""
                />
                {agent.errors.contact && (<span className="text-danger">{agent.errors.contact}</span>)}
                {!agent.errors.contact && (<small style={{ color: 'grey' }}>Contact data is used to help pre-fill all remaining fields</small>)}
                {hsContactInBlacklist && (
                  <Alert color="warning" className="w-100 mt-3">
                    <p className="py-2 text-dark">
                      This agent is on the blacklist and blocked from being added. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
                    </p>
                  </Alert>
                )}
                {hubilContactRecord && (
                  <div className="alert alert-warning">
                    <span style={{ color: 'black' }}>
                      Contact with email&nbsp;
                      <a
                        title="Link to HubSpot contact page"
                        rel="noreferrer"
                        target="_blank"
                        href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${hubilContactRecord.params.hs_object_id}/`}
                      >{hubilContactRecord.params.email}
                      </a>
                      &nbsp;already exists in HubSpot and has Contact Type set to Creator.
                    </span>
                  </div>
                )}
              </FormGroup>
              <hr />
              <FormGroup>
                <Label>First Name</Label>
                <Input
                  type="text"
                  id="first_name"
                  name="first_name"
                  onChange={e => this.handleInputChange('first_name', e)}
                  value={typeof agent.params !== 'undefined' && typeof agent.params.first_name !== 'undefined' ? agent.params.first_name : ''}
                  disabled={hsContactInBlacklist}
                />
                {agent.errors.first_name && (<span className="text-danger">{agent.errors.first_name}</span>)}
              </FormGroup>
              <FormGroup>
                <Label>Last Name</Label>
                <Input
                  type="text"
                  id="last_name"
                  name="last_name"
                  onChange={e => this.handleInputChange('last_name', e)}
                  value={typeof agent.params !== 'undefined' && typeof agent.params.last_name !== 'undefined' ? agent.params.last_name : ''}
                  disabled={hsContactInBlacklist}
                />
                {agent.errors.last_name && (<span className="text-danger">{agent.errors.last_name}</span>)}
              </FormGroup>
              <FormGroup>
                <Label>Agency</Label>
                <Select
                  value={agent.params?.agency ? agencyOptions.find(r => r.value === agent.params.agency) : null}
                  options={agencyOptions}
                  onChange={(option) => {
                    this.handleInputChange('agency', { target: option || { value: null } });
                    if (option?.label) {
                      this.handleChange('company', option.label);
                      this.handleInputChange('company', { target: { value: option.label } });
                    }
                  }}
                  placeholder=""
                  isDisabled={loadingAgencies}
                  isClearable
                />
                {agent.errors.agency && (<span className="text-danger">{agent.errors.agency}</span>)}
              </FormGroup>
              <FormGroup>
                <Label>Company</Label>
                <Input
                  type="text"
                  id="company"
                  name="company"
                  onChange={e => this.handleInputChange('company', e)}
                  value={typeof agent.params !== 'undefined' && typeof agent.params.company !== 'undefined' ? agent.params.company : ''}
                  disabled={hsContactInBlacklist}
                />
                {agent.errors.company && (<span className="text-danger">{agent.errors.company}</span>)}
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="new-email"
                  onChange={e => this.handleInputChange('email', e)}
                  value={typeof agent.params !== 'undefined' && typeof agent.params.email !== 'undefined' ? agent.params.email : ''}
                  onBlur={this.handleEmailBlur}
                  disabled={hsContactInBlacklist}
                />
                {agent.errors.email && (<span className="text-danger">{agent.errors.email}</span>)}
                {hsContactData.existContact && hsContactData.contact && (
                  <div className="alert alert-warning mt-1 py-2 pl-2">
                    <span style={{ color: 'black' }}>
                      {`${hsContactData.type === 'Agent' ? 'Agent ' : ''}Contact with email `}
                      <a
                        title="Link to HubSpot contact page"
                        rel="noreferrer"
                        target="_blank"
                        href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${hsContactData.contact.hs_object_id}/`}
                      >{hsContactData.contact.email}
                      </a>
                      {` already exists in HubSpot${hsContactData.type === 'Agent' ?
                        '. Use a unique email when creating a new Contact.' :
                        ' and has Contact Type set to Creator.'}`}
                    </span>
                  </div>
                )}
                {foundDupeViaEmail && (
                  <div className="alert alert-warning mt-1 py-2 pl-2">
                    <span style={{ color: 'black' }}>
                      Agent with same email is already exists in our Portal.
                    </span>
                  </div>
                )}
              </FormGroup>
              {row && (
                <>
                  <FormGroup>
                    <Label>Agent Portal Password</Label>
                    <InputGroup>
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        autoComplete="new-password"
                        onChange={e => this.handleInputChange('password', e)}
                        value={typeof agent.params !== 'undefined' && typeof agent.params.password !== 'undefined' ? agent.params.password : ''}
                        disabled={hsContactInBlacklist}
                      />
                      <Popover
                        id="popover-password"
                        placement="left"
                        isOpen={this.state.isPasswordPopoverOpen}
                        target="password"
                        trigger="focus"
                        toggle={this.togglePasswordPopover}
                      >
                        <PopoverHeader>Password requirements:</PopoverHeader>
                        <PopoverBody>
                          <p>
                            at least 1 Upper Case letter<br />
                            at least 1 Lower Case letter<br />
                            at least 1 number<br />
                            at least 1 special character ($.,)(!%^*#)<br />
                            at least 8 characters in length
                          </p>
                        </PopoverBody>
                      </Popover>
                      <InputGroupAddon addonType="append">
                        <button
                          style={{ height: 38, border: 'none' }}
                          type="button"
                          className={`form__form-group-button${showPassword ? ' active' : ''}`}
                          onClick={e => this.showPassword(e)}
                        ><EyeIcon />
                        </button>
                      </InputGroupAddon>
                    </InputGroup>
                    {agent.errors.password && (<span className="text-danger">{agent.errors.password}</span>)}
                  </FormGroup>
                  <FormGroup>
                    <Label>Confirm Agent Portal Password</Label>
                    <InputGroup>
                      <Input
                        type={showPasswordConfirm ? 'text' : 'password'}
                        id="passwordConfirm"
                        name="passwordConfirm"
                        autoComplete="new-confirm-password"
                        onChange={e => this.handleInputChange('passwordConfirm', e)}
                        value={typeof agent.params !== 'undefined' && typeof agent.params.passwordConfirm !== 'undefined' ? agent.params.passwordConfirm : ''}
                        disabled={hsContactInBlacklist}
                      />
                      <InputGroupAddon addonType="append">
                        <button
                          style={{ height: 38, border: 'none' }}
                          type="button"
                          className={`form__form-group-button${showPasswordConfirm ? ' active' : ''}`}
                          onClick={e => this.showPasswordConfirm(e)}
                        ><EyeIcon />
                        </button>
                      </InputGroupAddon>
                    </InputGroup>
                    {agent.errors.passwordConfirm && (<span className="text-danger">{agent.errors.passwordConfirm}</span>)}
                  </FormGroup>
                </>
              )}
              <div className="text-center align-self-center">
                <Button 
                  color="primary"
                  className="icomoon-save"
                  disabled={hsContactData.existContact || hsContactInBlacklist || foundDupeViaEmail}
                >
                  Save
                </Button>
                &nbsp;&emsp;&nbsp;&emsp;
                <Button
                  color="secondary"
                  onClick={this.closeFormDialog}
                >
                  Cancel
                </Button>
              </div>
            </form>
          )
          }
        </div>
      </Modal>
    );
  }
}

AgentForm.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  row: PropTypes.PropTypes.objectOf(PropTypes.any),
  hubilAgent: PropTypes.PropTypes.objectOf(PropTypes.any),
  adminUser: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
  setSelectedAgentOption: PropTypes.func,
};

AgentForm.defaultProps = {
  row: null,
  hubilAgent: null,
  setSelectedAgentOption: () => {},
};

const mapStateToProps = state => ({
  agentId: state.agentId,
  adminUser: state.session,
});


export default withNotice(connect(mapStateToProps)(AgentForm));
