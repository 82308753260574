import React from 'react';
import CopyToClipboard from '@/shared/components/CopyToClipboardLeft';
import Tooltip from '@material-ui/core/Tooltip';

const Deal = ({ record }) => {
  const agent = record?.params?.agents.length > 0 ? record?.params?.agents[0] : null;
  const influencer = record?.params?.influencer;
  const isEnablePayment = influencer?.payment?.enablePayments === true; 
  return (
    <div className='w-100'>
      <div className='font-weight-bold'>
      {agent ? (
        <div style={{ position: 'relative', display: 'flex' }}>
          <div
            style={{
              position: 'absolute', left: '0px', maxWidth: '25px', top: 'calc(50% - 12px)'
            }}
          >
            <Tooltip
              title={`Agent`.trim()}
            >
              <a
                href={`/agent/profile/${agent._id}`}
                target="_blank" rel="noreferrer">
                <img
                  style={{ maxWidth: '25px' }}
                  src={`/images/creator/agent-enabled.png`}
                  alt={isEnablePayment === false ? 'Enabled Agent' : 'Disabled Agent'}
                />
              </a>
            </Tooltip>
          </div>
          <div style={{ paddingLeft: '30px', maxWidth: '100%' }}><a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${record?.params?.dealObject.hs_object_id}`} target='_blank' rel="noreferrer">{record?.params?.dealObject?.dealname}</a></div>
        </div>
      ) : (<a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${record?.params?.dealObject.hs_object_id}`} target='_blank' rel="noreferrer">{record?.params?.dealObject?.dealname}</a>)}
      </div>
      {agent && (
        <div>
          {agent.first_name} {agent.last_name}
        </div>
      )}
      {agent && (
        <div>
          <CopyToClipboard
            value={agent.email}
          >
            {agent.email.length > 25 ? `${agent.email.slice(0, 25)}...` : agent.email}
          </CopyToClipboard>
        </div>
      )}
      <div>{record?.params?.dealObject?.dealTerms.type_of_deal}</div>
     
    </div>
  )
}

export default Deal;